.loadingBar {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: rgb(160, 160, 160);

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    z-index: 10000;
    color: black;
  }
}