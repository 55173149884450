//colours
$pennBlue: #131b659d;
$pennBlueOpacity: #091540c5;
$conflowerBlue: #7692ff;
$uranianBlue: #abd2fa;
$yinmnBlue: #3d518c;
$green: rgb(5, 143, 53);
$warning: #fa7304d7;
$silver: #313131;

$mainAccent: #66c7cc;
$mainAccentOpacity: #76abae9d;

$roboto: "Roboto", sans-serif;

$background: #131313;
$backgroundOpacity: #e9e9e9d1;

$borderLeft: 5px solid $warning;
$borderRadius: 15px;

$transition: all 0.1s;

// mixins
$mobile: 767px;
$tablet: 991px;
$desktop: 1200px;

@mixin mobile {
  @media screen and (max-width: #{$mobile}) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: #{($mobile + 1px)}) and (max-width: #{$tablet}) {
    @content;
  }
}

@mixin laptop {
  @media screen and (min-width: #{($tablet + 1px)}) and (max-width: #{$desktop}) {
    @content;
  }
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
